<template>
  <v-container class="fill-height wena my-0" fluid>
    <v-row justify="center">
      <v-col cols="12" sm="8" md="4" lg="4" xl="4">
        <v-system-bar color="secondary" dark class="pb-7">
          <v-spacer></v-spacer>
          <v-menu offset-y>
            <template v-slot:activator="{ on }">
              <v-btn text color v-on="on">
                <flag :iso="currentFlag" v-bind:squared="false" />
                <v-icon right color="primary">mdi-menu-down</v-icon>
              </v-btn>
            </template>
            <v-list dense>
              <v-list-item
                v-for="(entry, index) in languagess"
                :key="`${index}010`"
                @click="changeLocale(entry)"
              >
                <v-list-item-title>
                  <flag :iso="entry.flag" v-bind:squared="false" />
                  &nbsp;{{ entry.title }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-system-bar>
        <login-form />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import LoginForm from "../components/LoginForm.vue";
export default {
  name: "Login",
  components: {
    LoginForm,
  },
  data: () => ({
    currentFlag: "",
  }),

  created() {
    if (localStorage.getItem("flag") && localStorage.getItem("lang")) {
      this.$i18n.locale = localStorage.getItem("lang");
      this.currentFlag = localStorage.getItem("flag");
    }

    this.noInternet = window.navigator.onLine ? false : true;
    // this.currentFlag = localStorage.getItem("flag")
    //   ? localStorage.getItem("flag")
    //   : "pt";
    if (this.noInternet) {
      this.error = "No Internet Connection";
      this.showError = true;
    }
  },

  computed: {
    languagess: function() {
      return [
        { flag: "gb", language: "en", title: "English" },
        // { flag: "fr", language: "fr", title: "Français" },
        { flag: "pt", language: "pt", title: "Português" },
      ];
    },
  },

  methods: {
    changeLocale(locale) {
      this.$i18n.locale = locale.language;
      this.currentFlag = locale.flag;

      localStorage.setItem("lang", locale.language);
      localStorage.setItem("flag", locale.flag);
    },
  },
};
</script>

<style scoped>
.wena {
  background: #2e4c33;
}
</style>
