<template>
  <v-form ref="form">
    <v-card class="pb-10">
      <v-row justify="center">
        <v-col cols="4" md="5">
          <v-responsive class="pt-10">
            <v-img width="300px" src="/KENMARE.png"></v-img>
          </v-responsive>
        </v-col>
      </v-row>
      <v-card-title class="subtitle-1 text-center justify-center pb-10 px-12">
        {{ texts.title }}
      </v-card-title>
      <v-card-text class="px-10">
        <v-alert
          v-show="showError"
          dense
          outlined
          type="error"
          class="text-center"
        >
          {{ error }}
        </v-alert>
        <v-row class="d-flex flex-column justify-center text-center">
          <v-text-field
            v-model="user.email"
            label="email"
            outlined
            name="email"
            prepend-inner-icon="email"
            type="text"
            dense
            required
            :error-messages="emailErrors"
            v-model.trim="$v.user.email.$model"
          ></v-text-field>
          <v-text-field
            v-model="user.password"
            :label="$t('Password')"
            outlined
            name="password"
            prepend-inner-icon="lock"
            type="password"
            dense
            class="mb-0 pb-0"
            :error-messages="passwordErrors"
            v-model.trim="$v.user.password.$model"
          ></v-text-field>
        </v-row>
        <v-row class="d-flex flex-row justify-space-between align-center px-3 mb-4">
          <div class="d-flex">
            <div class="text--disabled">{{ $t("forgot_password") }}?</div>
            <a @click="goToRecover()" class="primary--text recover-login px-1">{{ $t("recover") }}</a>
          </div>
        </v-row>
        
        <v-row class="d-flex flex-column justify-space-between">
          <v-btn
            :loading="isLoading"
            :disabled="$v.$invalid || isLoading"
            type="submit"
            color="primary"
            class="mb-6"
            block
            large
            @click.prevent="submit"
            >{{ $t("submit_test") }}</v-btn
          >
          <v-btn block type="reset" @click.prevent="register()">{{
            $t("create_account")
          }}</v-btn>
        </v-row>
      </v-card-text>
    </v-card>
  </v-form>
</template>

<script>
import { required, email, minLength } from "vuelidate/lib/validators";
import { LOGIN_MUTATION } from "./../graphql/login";
import { SEARCH_USER_BY_EMAIL_OR_MOBILE_PHONE } from "./../graphql/mutation.gql";
import { formatError } from "@/utils";

import { mapActions } from "vuex";
import accessLevelMixins from "@/mixins/access-level";
export default {
  name: "LoginForm",
  mixins: [accessLevelMixins],
  data: () => ({
    emailOrMobilePhone: "",
    error: undefined,
    noInternet: false,
    isLoading: false,
    isLogin: true,
    isRecover: false,
    showError: false,
    user: {
      name: "",
      email: "",
      password: "",
    },
  }),

  validations() {
    const validations = {
      user: {
        email: {
          required,
          email,
        },
        password: {
          required,
          minLength: minLength(6),
        },
      },
    };

    return validations;
  },

  computed: {
    emailErrors() {
      const errors = [];
      const email = this.$v.user.email;
      if (!email.$dirty) {
        return errors;
      }
      !email.required && errors.push(`${this.$t("email_required")}!`);
      !email.email && errors.push(`${this.$t("enter_valid_email")}!`);

      return errors;
    },
    passwordErrors() {
      const errors = [];
      const password = this.$v.user.password;
      if (!password.$dirty) {
        return errors;
      }
      !password.required && errors.push(`${this.$t("password_required")}!`);
      !password.minLength &&
        errors.push(
          `${this.$t("enter_least")} ${
            password.$params.minLength.min
          } ${this.$t("characters")}!`
        );

      return errors;
    },
    requiredRules() {
      return [(v) => !!v || `${this.$t("email_required")}!`];
    },
    texts() {
      return this.isLogin
        ? { title: "Login", button: this.$t("btn_login") }
        : {
            title: `${this.$t("enter_email_user")}`,
            button: `${this.$t("submit_test")}`,
          };
    },
  },

  methods: {
    ...mapActions({
      setUserState: "auth/setUser",
    }),
    cancelRecover() {
      this.isLogin = !this.isLogin;
    },
    
    
    async submit() {
      this.isLoading = true;
      if (this.isLogin) {
        try {
          const { data } = await this.$apollo
            .mutate({
              mutation: LOGIN_MUTATION,
              variables: {
                ...this.user,
              },
            });

            this.$router.push({
              path: `verify-two-factory-code`,
              query: {
                redirect: this.$route.query.redirect,
                email: data.login.email,
              },
            });
        } catch (error) {
          this.error = formatError(error.message);
          switch (this.error) {
            case "Email":
              this.error = this.$t("enter_valid_email");
              break;

            default:
              this.error = this.$t("email_password_incorrect");
              break;
          }
          this.noInternet = window.navigator.onLine ? false : true;
          if (this.noInternet) {
            this.error = "No Internet Connection";
          }
          this.showError = true;
        } finally {
          this.isLoading = false;
        }
      } else {
        if (this.emailOrMobilePhone) {
          try {
            const { data } = await this.$apollo.mutate({
              mutation: SEARCH_USER_BY_EMAIL_OR_MOBILE_PHONE,
              variables: {
                emailOrMobilePhone: this.emailOrMobilePhone,
              },
            });

            const findedType = this.emailOrMobilePhone.search("@");
            this.$router.push({
              path: `recover_password`,
              query: {
                userAccount: { ...data.findedAccount },
                typeEnter: { type: findedType > -1 ? "email" : "number" },
              },
            });
          } catch (error) {
            this.error = formatError(error.message);
            this.showError = true;
          } finally {
            this.isLoading = false;
          }
        } else {
          this.error = this.$t("email_required");
          this.showError = true;
          this.isLoading = false;
        }
      }
    },
    register() {
      this.$router.push("/candidate/register");
    },

    goToRecover() {
      this.$router.push("/recover");
    }
  },
};
</script>

<style scoped>
.recover-login {
  cursor: pointer;
}

.text-start {
  margin-top: -15px;
}
</style>